<template>
  <div>
    <siteHeader />
    <div class="container-fluid mt--9">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card shadow type="secondary">
            <form>
              <h3>User</h3>
              <hr class="my-4" />
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-md-12">
                    <base-input
                      :value="model.username"
                      alternative=""
                      label="Username"
                      placeholder="Username"
                      input-classes="form-control-alternative"
                      @input="(event) => (model.username = event.target.value)"
                    />
                    <div class="error mb-2" v-if="v$.model.username.$error">
                      Username is required.
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <base-input
                      :value="model.password"
                      alternative=""
                      type="password"
                      label="Password"
                      placeholder="Password"
                      input-classes="form-control-alternative"
                      @input="(event) => (model.password = event.target.value)"
                    />
                    <div class="error mb-2" v-if="v$.model.password.$error">
                      Password is required.
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      :value="model.confirmpassword"
                      alternative=""
                      type="password"
                      label="Confirm Password"
                      placeholder="Confirm Password"
                      input-classes="form-control-alternative"
                      @input="
                        (event) => (model.confirmpassword = event.target.value)
                      "
                    />

                    <div
                      class="error mb-2"
                      v-if="v$.model.confirmpassword.$error"
                    >
                      Confirm Password.
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <base-input
                      alternative=""
                      label="Email"
                      placeholder="Email"
                      type="email"
                      input-classes="form-control-alternative"
                      :value="model.email"
                      @input="(event) => (model.email = event.target.value)"
                    />
                    <div class="error mb-2" v-if="v$.model.email.$error">
                      Invalid Email .
                    </div>
                  </div>
                </div>
                <div class="row1">
                  <div class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="customCheck1"
                      v-model="model.is_active"
                      false-value="2"
                      true-value="1"
                    />
                    <label class="custom-control-label" for="customCheck1"
                      >Active?</label
                    >
                  </div>
                </div>
              </div>
              <div class="row" v-if="id.data == 'undefined'">
                <div class="col-md-5"></div>
                <div class="col-md-4">
                  <button
                    type="button"
                    class="btn btn-default"
                    v-on:click="create()"
                  >
                    Create
                  </button>
                </div>
              </div>
              <div class="row" v-if="id.data != 'undefined'">
                <div class="col-md-5"></div>
                <div class="col-md-4">
                  <button
                    type="button"
                    class="btn btn-default"
                    v-on:click="Update()"
                  >
                    Update
                  </button>
                </div>
              </div>
            </form>
            <br />
            <div v-if="isLoading">
              <base-alert type="success" dismissible>
                <span class="alert-inner--text"
                  ><strong>Success!</strong> Record Created Successfully</span
                >
                <button
                  type="button"
                  class="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </base-alert>
            </div>
            <div v-if="isUpdateLoading">
              <base-alert type="success" dismissible>
                <span class="alert-inner--text"
                  ><strong>Success!</strong> Record Updated Successfully</span
                >
                <button
                  type="button"
                  class="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </base-alert>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { RepositoryFactory } from "../../repositories/RepositoryFactory";
import useVuelidate from "@vuelidate/core";
import { required, email, sameAs } from "@vuelidate/validators";
const usersRepository = RepositoryFactory.get("users");
export default {
  name: "add_member",
  data() {
    return {
      v$: useVuelidate(),
      id: {},
      isLoading: false,
      isUpdateLoading: false,
      model: {},
    };
  },

  validations() {
    return {
      model: {
        username: { required },
        password: { required },
        confirmpassword: {
          required,
          sameAs: sameAs(this.model.password),
        },
        email: { required, email },
      },
    };
  },

  methods: {
    async create() {
      this.v$.$validate(); // checks all inputs
      if (this.v$.$error) {
        return;
      }
      // this.model.Subscriptions = JSON.stringify(this.model.Subscriptions);
      let data = RepositoryFactory.JSON_to_URLEncoded(this.model, "data", []);
      let response = await usersRepository.create(data);
      if (response.data.status == 100) {
        this.isLoading = true;
        setTimeout(() => {
          this.isLoading = false;
          this.$router.push("/user/view_user");
        }, 1000);
      }
    },
    async Update() {
      this.v$.$validate(); // checks all inputs
      if (this.v$.$error) {
        return;
      }
      delete this.model.edit;
      // this.model.Subscriptions = JSON.stringify(this.model.Subscriptions);
      let data = RepositoryFactory.JSON_to_URLEncoded(this.model, "data", []);
      let response = await usersRepository.create(data);
      if (response.data.status == 101) {
        this.isUpdateLoading = true;
        setTimeout(() => {
          this.isUpdateLoading = false;
          this.$router.push("/user/view_user");
        }, 1000);
      }
    },
  },
  mounted() {
    this.id = this.$route.params;
    this.model = {};
    usersRepository.getUser(this.id.data).then((data) => {
      if (data.data.length != 0) {
        this.model = data.data[0];
      }
    });
  },
};
</script>
<style></style>
